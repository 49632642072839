.video-carousel {

    .carousel__pagination-flex-container {

        display:flex;
        gap:50px;
        margin-top:20px;
        align-items: center;

        > div {
            flex:1;
        }

        .swiper-scrollbar{
            height: 1px;
            width: auto;
            &-drag{
                background-color: $dark;
            }

            flex-shrink:1;
            flex-grow:1;       }

        .carousel__pagination {
            flex-shrink:1;
            flex-grow:0;
        }

        .carousel-header{
            position: relative;
            user-select: none;

            flex-shrink:1;
            flex-grow:0;
            display:flex;
            flex-direction: row;
            gap:30px;


            .carousel__swiper-button-next, .carousel__swiper-button-prev{
                position: relative;
                display: inline-block;
                top: 0;
                left: auto;
                right: auto;
                outline: none;

                margin-top:0;
            }
        }
    }

    .swiper-slide {
        .slide-overlay {
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            z-index:10;
            background-color:#00000099;
            color:white;
            padding:70px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-breakpoint-down( md ){
                padding:40px;
            }

            @include media-breakpoint-down( sm ){
                padding:25px;
            }

            .overlay-quote {
                font-weight:300;
                line-height:1.5;
                margin-bottom:50px;

                @include media-breakpoint-down( sm ){
                    margin-bottom:10px;
                    font-size:1.2rem;
                }

                @include media-breakpoint-down( xs ){
                    margin-bottom:10px;
                    font-size:1.1rem;
                }
            }

            .overlay-name-title-container {
                .overlay-name,
                .overlay-title {
                    font-size:1.5rem;
                    font-weight:bold;
                    letter-spacing:0;
                    font-family:GTAmericanExtRg,sans-serif;
    
                    @include media-breakpoint-down( md ) {
                        font-size:1.0rem;
                    }
                }

                @include media-breakpoint-down( md ) {
                    display:flex;
                    flex-direction: row;

                    .overlay-title {
                        margin-left:15px;
                        font-weight: normal;
                    }
                }
            }

            .button-container {
                @include media-breakpoint-down( md ) {
                    margin-top:10px;
                }
                .btn {
                    color:white;

                    @include media-breakpoint-down( md ) {
                        height:40px;
                        font-size:0.7rem;
                    }
                }
            }
        }

        &.swiper-slide-overlay-hidden {
            .slide-overlay {
                display:none;
            }
        }
    }
    .wistia-container {
        width:100%;
        aspect-ratio: 16 / 9;

    }

    .carousel__swiper-thumbs-container {
        margin-top:25px;

        .swiper-slide {
            img {
                transition: 0.3s;
                filter:grayscale(0.95);
            }

            cursor:pointer;

            &[data-thumbs-active='active'],
            &:hover {
                img {
                    filter: grayscale(0);
                }
            }
        }


    }
}