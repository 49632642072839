.modal-backdrop.show {
    background-color:rgba( $white ,1);
    opacity: 0;
}



/* != MX-Panel
-------------------------------------------------------------- */


.modal--full-screen {
  background: rgba(0,0,0,.7);
    .modal-content{
        background:none !important;
        box-shadow: none;
        border:none;
        color:$dark;
    }
    .modal-body{
        box-shadow: none;
    }
    .close{
      position: absolute;
      right: 20px;
      top: 20px;
      background:$white;
      z-index: 100;
      color: white;
      opacity: 1;
      cursor: pointer;
        .icon-close:before{ color:$green; }
        &:hover{
          color:white;
        }
      }
    .modal-dialog{
      padding-top: 50px;
      h3{
        margin-top: 10px;
      }
			@include media-breakpoint-down( sm ){
				right: 0px;
				top: 0px;
			}
      @include media-breakpoint-up( md ){
          max-width: 80%;
          top: 50%;
          transform: translate( 0, -50%) !important;
      }
    }
}

.modal--about-card{
  .modal-body{
    background-color: $white;
  }
}

.modal--video{
  background: rgba(0,0,0,.7);
}
