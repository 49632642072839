.search-box{
    .input-container{
        &::before{
            content: '';
            @include icomoon( '\e905');
            display: flex;
            justify-content: center;
            align-items:center;
            color: $dark;
            top: 0;
            left: 14px;
            height: 100%;
            width: 50px;
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;
            // border: 10px solid $white;
            @include gradient('blue-green');
            position:absolute;
        }
        // &::after{
        //     content: 'Search a topic... |';
        //     position: absolute;
        //     display: flex;
        //     justify-content: center;
        //     align-items:center;
        //     top:0;
        //     left:60px;
        //     height:100%;
        //     color: $gray;
        // }

        input{
            border-radius: 35px;
            padding-left: 60px;
            color: $white;
            border: 1px;
            height: 60px;
        }
    }
}

.block--article{
    margin-bottom: 30px;
    border-bottom: 2px solid #d1d1d1;
}

#section-search{
    padding-bottom: 10px !important;
    .row-grid{
        display: grid !important;
        grid-template-columns: repeat(2,50%);
        column-gap: 50px;
        @include media-breakpoint-down(sm){
            grid-template-columns: repeat(1,100%);
            column-gap:none;
        }
    }
}

.pagers{
  .page-numbers{
    padding: 0 5px;
    &.current{
      color: $green;
      border-bottom: 4px solid $green;
    }
  }
}
