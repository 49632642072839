.row-accordion{
	.btn-secondary{
			// margin-left: 10px !important;
	}
	.blocks-container{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.accordion-list{

			.block{
				width:100%;
				margin-bottom:15px;
				@include media-breakpoint-up( lg ){
					// width:calc( 50% - 15px );
					margin:15px 7.5px 0px 7.5px;
					min-height: 160px;
				}
				background:$dark;
				.block__header{
					padding:30px 20px 30px 30px;
					*{ color:$white; }
					color:$white;
					display: flex;
					justify-content: center;
					align-items: center;
					.block__header__text {
						.title {
							// transition: all 1.5s cubic-bezier(0.23, 1, 0.320, 1);
							// transition:all ease-in .5s;
							font-size:1.3125rem;
						}
						.body{
							height:0;
							opacity: 0;
							line-height: 1.2;
						}
						a{
							border-bottom: 4px solid rgba(141,197,62,.5);
							&:hover{
								border-bottom-color: rgba(228,67,71,.4);
								text-decoration: none;
							}
						}
						padding-right:24px;
						width:100%;
					}
					.block__header__icon{
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100px;
						width:90px;
						position: relative;
						&::before{
							content : '';
							@include gradient( 'blue-green-black');
							position: absolute;
							left:0;
							width:1px;
							height:100%;
						}
						.icon-close{
							// transition: all .1s cubic-bezier(0.23, 1, 0.320, 1);
							display: inline-block;
							transform: rotate(45deg)
						}
					}
				}
				.block__body{
					color:$white;
					height:0px;
					opacity: 0;
					display: none;
				}
				&.active{
					.block__header__text{
						.title{
							// opacity: 0;
							// height:0;
							// transition: all ease-in-out .5s;
						}
						.body{
							transition: all 1.5s cubic-bezier(0.23, 1, 0.320, 1);
							opacity: 1;
							height:auto;
						}
					}
				}
				&:hover, &.active{
					cursor: pointer;
					.block__header__icon .icon-close{
						transition: all .1s cubic-bezier(0.23, 1, 0.320, 1);
						transform: rotate(0deg)
					}
				}
			}
		}
	}
}
