.testimonial-single.container {

  .testimonial-single-wrapper {
  }

  .testimonial-single-content {
    max-width:100%;
    padding:15px 15px 15px;

    img {
      max-width:50%;
      margin-bottom:15px;

      @include media-breakpoint-up( sm ) {
        max-width:200px;
      }

      @include media-breakpoint-up( md ) {
        margin-bottom:25px;
        max-width:250px;
      }
    }

    .testimonial__body {
      line-height:1.6em;
      font-size:1.125rem;
    }

    .testimonial__header {
      margin-top:2rem;
      font-size:1.2em;
    }
  }

  @include media-breakpoint-up( md ) {
   .testimonial-single-content {
      padding:25px 75px 35px;

      .testimonial__body {
        line-height:1.6em;
        font-size:1.75rem;
      }

      .testimonial__header {
        font-size:1.4em;
      }
    }
  }

  &.testimonial-bordered {

    .testimonial-single-wrapper {
      background:linear-gradient(138deg,#12ace3 0,#8dc53e 100%);
      padding:15px;
    }

    .testimonial-single-content {
      background-color:#fff;
    }

  }

  &.testimonial-semi-transparent,
  &.testimonial-transparent {

    .testimonial__header::before {
      background:linear-gradient(138deg,#12ace3 0,#8dc53e 100%);
      background:linear-gradient(138deg,#12ace3 0,#8dc53e 85%, rgba(255,255,255,0.1) 100%);
      height:4px;
      width:400px;
      max-width:100%;
      margin:auto;
      content:'';
      display:block;
      margin-bottom:30px;
    }
  }

  &.testimonial-semi-transparent {
    .testimonial-single-wrapper {
      background:linear-gradient(to bottom,rgba(255,255,255,.5) 0,rgba(255,255,255,0) 50%);
    }
  }
}