.row-tabs{
	// [aria-expanded="false"]{
	// 	color: $gray;
	// }
	.block__header {
		position: relative;
		// padding-right:20px;
		display: flex !important;
		flex-direction: column !important;
		border-bottom: none;
		padding-left: 50px;
		.nav-link{
			// padding: 0rem !important;
			border: none;
			color: #495057;
			position: relative;
    		right: 50px;
   			padding-left: 50px;
			padding-top: 15px;
    		padding-bottom: 15px;
			@include media-breakpoint-down(sm){
				width:100%;
			}
			@include media-breakpoint-up(sm){
				width:80%;
			}
			@include media-breakpoint-up(xl){
				width:100%;
			}
		}
		.nav-link.active{
			background:none;
			border-color: transparent;
			color: $black;
			box-shadow: #dde1e4 -1px 0 7px 3px;
			position: relative;
    		right: 50px;
   			padding-left: 50px;
			padding-top: 15px;
    		padding-bottom: 15px;
		}
		.nav-link:hover{
			border-color: transparent;
			box-shadow: #dde1e4 -1px 0 7px 3px;
			position: relative;
    		right: 50px;
   			padding-left: 50px;

		}
		&:after{
			content: '';
			position: absolute;
			height:100%;
			border-right:1px solid $gray;
			right:0;
			top:0;
		}
		li{
			padding-left:12px;
			list-style: decimal-leading-zero;
			margin-bottom:15px;
			font-family: GTAmericanLt;
			@include media-breakpoint-up(sm){
				width:350px;
			}
			a:hover{
				color: $black;

			}
		}
	}
	.block__body{
		.big-body{
			line-height: 1.5;
		}
		.tab-pane{
			@include media-breakpoint-up( md ){
				column-count: 2;
				column-gap: 30px;
			}
		}
	}
}
