
//Headings
h1, .h1{
  font-family: 'GTAmericanExtRg', sans-serif;
  font-size: 1.75rem; // 28px
  @include media-breakpoint-up(lg){
    font-size: 3rem; // 48px
  }
}

h2, .h2{
  font-family: 'GTAmericanExtRg', sans-serif;
  font-size: 1.75; // 28px
  @include media-breakpoint-up(lg){
    font-size: 3rem; // 48px
  }
}

h3, .h3{
  font-family: 'GTAmericanExtRg', sans-serif;
  font-size: 1.75rem; // 28px
}

h4, .h4{
  font-family: 'GTAmericanExtBd', sans-serif;
  font-size: 1rem; // 16px
}


// Display Classes
.display-1{
  font-family: 'GTAmericanExtRg', sans-serif;
  font-size: 2.625rem; // 42px
  line-height: 1;
  @include media-breakpoint-up(lg){
    font-size: 4.5rem; // 72px
  }
}

.display-2{
  font-family: 'GTAmericanExtRg', sans-serif;
  font-size: 1.75rem; // 28px
  padding-bottom: 30px;
  @include media-breakpoint-up(lg){
    font-size: 3rem; // 48px
  }
}

.display-3{
  font-size: 1.75rem; // 28px
}

.display-4{
  font-family: 'GTAmericanExtBd', sans-serif;
  font-size: 1rem;
}

.display-5{
  font-family: 'GTAmericanExpRg', sans-serif;
  font-size: 1rem;
}

body{
  font-family: $GTLt;
}

p{
  line-height: 1.75;
}

.big-body, .big-body p{
  font-size: 1.125rem;
}

.eyebrow{
  margin-bottom: 20px;
}

a{
  color:$dark;
}

.editable-content a{
	color:$dark;
	// border-bottom:4px solid rgba( $green , .5);

	&:hover{
		text-decoration: none;
		// border-bottom-color:rgba( $red , .4);
		color:$dark;
	}
}

a .read-more, .read-more{
  color:$dark;
  border-bottom:4px solid rgba( $green , .5);

  &:hover{
    text-decoration: none;
    border-bottom-color:rgba( $red , .4);
    color:$dark;
  }
}
a:hover .read-more{
  text-decoration: none;
  border-bottom-color:rgba( $red , .4);
  color:$dark;
}

a[href=""]{
  cursor:default;
  &:hover{
    text-decoration: none;
  }
}

.block--article{
  .read-more{
    &:after{
      content: "";
      display: block;
      width: 19%;
      margin-top: -9px;
      border-bottom:6px solid rgba( $green , .5);
      transition: .5s;
    }
    &:hover{
      &:after{
        width: 4%;
      }
    }
  }
  .b-header{
    &:hover{

    }
  }
  a{
    &:hover{
      color: #1c1c1c;
    }
    text-decoration: none;
  }
  &:hover{
    border: solid 1px #CCC;
    -moz-box-shadow: 1px 1px 5px #999;
    -webkit-box-shadow: 1px 1px 5px #999;
    box-shadow: 1px 1px 5px #999;
    cursor: pointer;
  }
}
