.image-carousel{
  .swiper-scrollbar{
    height: 1px;
    margin-left: 60%;
    width: auto;
    top: 15px;
    @include media-breakpoint-up(xl){
      margin-left: 50%;
    }
    &-drag{
      background-color: $dark;
    }
  }
  .carousel-header{
    position: relative;
    user-select: none;
    @include media-breakpoint-up(md){
      margin-bottom: 50px;
    }
    .carousel__title{
      display: block;
      margin-bottom: 50px;
      padding-right: 30px;
      @include media-breakpoint-up(md){
        display: inline-block;
        margin-bottom: .5rem;
      }
    }
    .carousel__swiper-button-next, .carousel__swiper-button-prev{
      position: relative;
      display: inline-block;
      top: 0;
      left: auto;
      right: auto;
      margin-right: 30px;
      outline: none;
    }
  }
  .swiper-slide{
    text-align: center;
    min-width: 100px;
    @include media-breakpoint-up(md){
      min-width: auto;
    }
    .slide-image{
      max-width: 75px;
      @include media-breakpoint-up(md){
        max-width: 160px;
      }
    }
  }
}

.progress{
  width: 200px;
  height: 50px;
  background-color: $green;
}
