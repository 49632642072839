/* != Header
-------------------------------------------------------------- */
.main-header{

	position:fixed;
	top:0;
	left:0;
	width:100%;
	display:block;
	z-index:999;
	&.squish{
		background:white;
    }
    background: rgba( $white, .8 );
    @include media-breakpoint-down( md ){
        background: rgba( $white, 1 );
    }
    .navbar{
        padding: .5rem 1rem 0px 1rem;
				.navbar-collapse{
					.navbar-nav{
						@include media-breakpoint-down(sm){
							height: 120vh;
						}
					}
				}
    }
    .nav-link{
        color:$dark;
        cursor: pointer;
        font-size:.75rem;
        text-transform: uppercase;
				font-family: $GTExtBd;
        .icon-chevron{
            padding-left:12px !important;
            font-size:.5rem !important;
						pointer-events: none;
        }
				@include media-breakpoint-up(md){
					padding-left: 2rem !important;
				}
    }
    #svg-logo-text{
        transition:  all .2s ease-out;
        fill:$dark;
    }
    body.mega-menu--open & {
        background:$dark;
        a:not( .navbar-brand) {
            opacity: .3;
            color:$white;
            &.active{
                opacity: 1;
                color:$green;
							.icon-chevron{
								font-size: .75rem !important;
								&:before{
									content: "\e907";
									color: $green;
								}
							}
            }
        }
        .about-menu-toggler span{
            background: $light;
            opacity: .3;
        }
        #svg-logo-text{
            transition:  all .2s ease-out;
            fill:$white;
        }
    }
    @include media-breakpoint-down( sm ){
        body.navbar-open & {
            #top-nav{
                height:100vh;
                padding:50px 20px;
                overflow:auto;
            }
            background:$dark;
            a:not( .navbar-brand) {
                color:$white;
            }
            .nav-item{
                padding: 24px 0px;
                border-bottom: 1px solid $gray;
                &:last-child(){
                    border-bottom:none;
                }
                transition: all .2s ease-in;
                color:$white;
                .icon-chevron{
                    transition: all .2s ease-in;
                    display: inline-block;
                    transform: rotate(-90deg) translateX(-22%);
                    font-size:7px !important;
                    &:before{
                        color:$white;
                    }
                }
                &:hover{
                    color:$green;
                    transition: all .2s ease-out;
                    .icon-chevron{
                        transform: rotate(-90deg) translateX(-22%) translateY(5px);
                        &::before{ color:$green;}
                    }
                }
            }

            #svg-logo-text{
                transition:  all .2s ease-out;
                fill:$white;
            }

        }
    }
}

.menu-trigger{
	position:absolute;
	top:55%;
	left:0;
	width:5px;
	height:5px;
	opacity:0;
	pointer-events:none;
}

.navbar-toggler {
    cursor: pointer;
    height: 29px;
    @include media-breakpoint-up( md ){
        width: 77px;
    }
    padding:0px;
    margin-left:10px;
    margin-right: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &:focus{ outline:0; }
    span.bar{
        display: block;
        width: 25px;
        height: 2px;
        margin-top: 0px;
        margin-bottom: 3px;
        position: relative;
        border-radius: 3px;
        z-index: 1;
		transform-origin: 22px 2px;
		background-color:$dark;
        pointer-events: none;
    }
    span.icon-close{
        display: none;
        &:before{
            color:$green;
        }
    }
    &[aria-expanded="true"]{
        span.bar{
            opacity: 1;
            display: none;
        }
        span.icon-close{
            display: inline-block;
        }
    }
}

.about-menu-toggler {
    cursor: pointer;
    padding:0px;
    width: 77px;
    height: 29px;
    margin-left:10px;
    &:focus{ outline:0; }
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: transparent;
    outline:none;
    border:none;
    span{
        display: block;
        width: 25px;
        height: 2px;
        margin-top: 0px;
        margin-bottom: 3px;
        position: relative;
        border-radius: 3px;
        z-index: 1;
		transform-origin: 22px 2px;
		background-color:$dark;
        pointer-events: none;
    }
}


.navbar-right{
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down( md ){
        .btn-primary {
            display: none;
        } 
    }

    @include media-breakpoint-down( sm ){
        display:none;
    }

    .btn-primary{
        margin-top:-10px;
        margin-right:-16px;
				margin-bottom: 0;
        border-radius: 0px !important;
        &:after{
            border-radius: 0px !important;
        }
    }
}

.sticky-start{
		position: fixed;
    margin-bottom: 0;
    top: calc(100vh - 90px);
    z-index: 11;
		border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 80%;
}
