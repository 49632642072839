
/* != MX-Panel
-------------------------------------------------------------- */
.video-item {
	position: relative;
	.btn-play{
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		text-align:center;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&:hover{
			opacity: .7;
		}
		.fa{ font-size:32px; margin-left:4px;}
	}
	
}

.video-player--inline{
	min-height:250px;
	@include media-breakpoint-up(lg) {
		min-height:350px;
	}
}

.modal--video {
    .modal-content{
        background:none !important;
        box-shadow: none;
        border:none;
    }
    .modal-body{
        box-shadow: none;
        p{
            color:white;
            padding:20px;
            font-size:18px;
        }
    }
    .modal-dialog{
        .close{
            position: absolute;
            right: -10px;
            top: -10px;
            z-index: 100;
            color: white;
            opacity: 1;
            cursor: pointer;
            &:hover{
                color:white;
            }
			@include media-breakpoint-down( sm ){
				right: 0px;
				top: 0px;
			}
        }
        @include media-breakpoint-up( md ){
            max-width: 80%;
            top: 50%;
            transform: translate( 0, -50%) !important;
        }
    }
}


.video-iframe-container{
	padding:50% 0 0 0;position:relative;
}
video.video-iframe{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	max-width: 100%;
	height:100%;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	@include media-breakpoint-up( lg ){
		max-height: 75vh;
		width:auto;
	}
}
iframe.video-iframe{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	@include media-breakpoint-up( lg ){
		max-height: 75vh;
		width:100%;
	}
}
