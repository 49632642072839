/* != MX-Panel
-------------------------------------------------------------- */
.mx-panel-sec{
	@include media-breakpoint-up(md) {
		display:flex;
		align-items:stretch;
		min-height:80vh;
	}
	&--2-col{
		.mx-panel{
			width:100%;
			@include media-breakpoint-up(md) {
				width:50%;
			}
		}
	}
	&.theme{
		&--dark{
			background:$dark ;
			.mx-panel{
				&--content{
					color:$light;
				}
			}
		}
		&--light{
			// background:$light ;
			.mx-panel{
				&--content{
					color:$dark;
				}
			}
		}
	}
	&__body{
		padding-bottom: 30px;
	}

}

.mx-panel{
	padding:30px;
	display:flex;
	flex-direction:column;
	justify-content:center;
	width:100%;
	position: relative;
	@include media-breakpoint-up(md) { padding:50px; }
	@include media-breakpoint-up(lg) { padding:100px; }

	&--content{
		// background:$gold;
		color:$white;
	}
	&--img{
		background-position:center center;
		background-repeat:no-repeat;
		background-size:cover;
		align-items:center;
		@include media-breakpoint-down(md) {
			min-height:100vw;
		}
	}
}
