/* != Helpers
-------------------------------------------------------------- */
*{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// iOS fix for tall modals to allow scroll
@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */ 
  	html,body {
    -webkit-overflow-scrolling : touch !important;
    overflow: auto !important;
    height: 100% !important;
	}
}

/*html, body { overflow-x: hidden !important; }*/

.position-relative{ position: relative;}

.text{
    &-underline{ text-decoration: underline; }
    &-upper{ text-transform: uppercase; }
    &-initial{ text-transform: initial; }
}

@mixin flex-center() {
	display: flex;
	justify-content: center;
	align-items: center;
}


//-------------- Container Classes
.container-container{
	@include media-breakpoint-down(sm) {
		display:block;
		overflow:hidden;
	}
	@include media-breakpoint-up(md) {	
		display:flex;
		align-items:center;
		width:100%;
	}
}
.container-left,
.container-right{
	width:100%;
	display:block;
	float:left;
	padding-left:15px;
	padding-right:15px;
	@include media-breakpoint-up(md) {
		width:50%;
		max-width:360px;
	}
	@include media-breakpoint-up(lg) {
		max-width:480px;
	}
	@include media-breakpoint-up(xl) {
		max-width:570px;
	}
}
.container-left{
	@include media-breakpoint-up(md) {
		margin-left:calc(50% - 360px);
	}
	@include media-breakpoint-up(lg) {
		margin-left:calc(50% - 480px);
	}
	@include media-breakpoint-up(xl) {
		margin-left:calc(50% - 570px);
	}
}
.container-fluid-left,
.container-fluid-right{
	width:100%;
	display:block;
	float:left;
	padding-left:15px;
	padding-right:15px;
	@include media-breakpoint-up(md) {
		width:50%;
	}
}




@mixin icomoon( $content ) {
	content:$content;
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
}




