.mx-section-cta{
  &.two_columns{
    .header{
      @include media-breakpoint-up(md){
        padding-bottom: 0;
        border-right: 1px solid $gray;
      }
    }
  }
}
