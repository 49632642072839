@mixin gradient( $which ){
	@if $which ==  blue-green {
		background: linear-gradient(138deg,$blue-light 0%, $green 100%);
	}
	@if $which ==  blue-green-black {
		background: linear-gradient(138deg,$blue-light 0%, $green 70% , $dark 100%);
	}
	@if $which ==  blue-blue {
		background: linear-gradient(138deg,$blue-light 0%, $blue 100%);
	}
	@if $which ==  purple-purple {
		background: linear-gradient(138deg,$purple-light 0%, $purple 100%);
	}
	@if $which ==  orange-red {
		background: linear-gradient(138deg,$orange 0%, $red 100%);
	}
	@if $which ==  green-green {
		background: linear-gradient(138deg,#5da51e 0%, $green 100%);
	}
	@if $which ==  red-red {
		background: linear-gradient(138deg,$red 0%, #c42327 100%);
	}
	@if $which ==  green-blue {
		background: linear-gradient(138deg,$green 0%, $green-light 65%, $blue-light 100%);
	}
}
@mixin text-gradient( $which ){
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	@if $which ==  blue-green {
		background: linear-gradient(138deg,$blue-light 0%, $green 100%);
	}
}
