.testimonial-logo-carousel {

	position: relative;

	.swiper-slide{

		position: relative;
		min-height: 950px;

		padding-right:30px;

		@include media-breakpoint-up(md){
			min-height: 100%;
		}

		img{
			@include media-breakpoint-up(md){
				z-index:10;
				position:relative;
				height:auto;
				max-width:50%;
				background-color: white;
				padding-bottom:10px;
				padding-right:10px;
			}
		}
		.content-card{
			position: relative;
			right: 0;
			background: $dark;
			color: $white;
			z-index: 1;
			padding: 40px;

			@include media-breakpoint-up(md){
				padding: 60px;
				padding-top:80px;
				margin-top:-20px;
				margin-left:80px;
				/*position: absolute;
				top: 50%;
				transform: translateY(-50%);
				*/
			}
			.quotes{
				height: 40px;
				width: 50px;
				background-image: url('../img/quotes.png');
				display: inline-block;
				background-size: contain;
				background-repeat: no-repeat;
			}
			.testimonial__body{
				display: inline;
			}
			.testimonial__label{
				&:before{
					content: '';
					display: block;
					width: 100%;
					height: 2px;
					@include gradient( blue-green );
					border-top-right-radius: 100%;
					margin: 50px 0 15px;
				}
			}
			.btn{
				margin-top: 45px;
				color: $white;
				&:after{
					color: $white;
				}
			}
		}
	}
	
	
	.testimonial__pagination{
		position: absolute;
		bottom: 70px;
		right: 20%;
		z-index: 2;
		width: auto;
		@include media-breakpoint-down(md){
			position:static;
			display: flex;
			width:10%;
			margin-right: 0;
			margin-left: 0;
		}
	}
	.testimonial__swiper-button-next, .testimonial__swiper-button-prev{
		position: absolute;
		height: 66px;
		width: 66px;
		border: 1px solid $gray;
		border-radius: 100%;
		top: auto;
		bottom: 50px;
		z-index: 3;
		&:before{
			@include icomoon( '\e900');
			font-size:.875rem;
			color: $black;
			padding: 25px 22px;
			top: 0;
			position: absolute;
		}
	}
	.testimonial__swiper-button-next{
		@include media-breakpoint-down(md){
			bottom: 25px;
		}
	}
	.testimonial__swiper-button-prev{
		height: 65px;
		left: 84%;
		// @include media-breakpoint-up(lg){
			//   left: 85%;
			// }
			@include media-breakpoint-down(md){
				left: 70%;
				bottom: 25px;
			}
			@include media-breakpoint-down(sm){
				left: 55%;
				bottom: 25px;
			}
			&:before{
				transform: rotate(180deg);
			}
		}
		
	}
	