.feature-list{
	background: $dark;
	margin-bottom: 60px;
	*{
		color:$white;
	}
	.feature-list__item{

		display: flex;
		align-items: center;
		margin-bottom:45px;
		&:hover{
			text-decoration: none !important;
		}
		.feature-list__content{

		}
		.item-label{
			font-size:.75rem;
			color:$gray;
		}
		.item-header{
			&::after{
				content: '';
				position: relative;
				@include icomoon( '\e900');
				color: $white;
				top: 2px;
				left: 10px;
				transition: left ease-in-out .15s;
				width: 60px;
				&:hover{
					color: $green;
				}
			}
			&:hover{
				transition: color ease-in-out .15s;
				color: $green;
			}
			&:hover::after{
				content: '';
				position: relative;
				@include icomoon( '\e900');
				color: $green;
				top: 2px;
				left: 20px;
				width: 60px;
				transition: left ease-in-out .15s;
				&:hover{
					color: $green;
				}
			}
		}
	}
	.feature-list__image{
		width:100px !important;
		height:100px !important;
		min-width: 100px !important;
		max-width: 100px !important;
	}
	.btn{
		margin-top: 120px;
		@include media-breakpoint-down(sm){
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
}
