.alert{
  background-color: $dark !important;
  a{
    color: $white;
    text-decoration: underline;
    &:hover{
      color: $white;
    }
  }
  &.fixed-bottom{
    max-width: 1200px;
    margin: 0 auto;
    border: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 2rem;
    @include media-breakpoint-down(sm){
      flex-direction: column;
    }
    .alert__close{
      @include media-breakpoint-down(sm){
        position: absolute;
        top: 0;
      }
    }
    .alert__header{
      @include media-breakpoint-down(sm){
        margin-bottom: 1em;
      }
    }
  }
  .icon-close{
    &:before{
      color: $green;
    }
  }
  &__close{
    height: 45px;
    opacity: 1;
  }
}
