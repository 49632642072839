$white: #ffffff;
$black: #000000;
$green: #8dc53e;
$green-light: #75c171;
$blue: #5968ae;
$blue-light: #12ace3;
$purple: #5f2368;
$purple-light: #9c4a9c;
$orange: #f16e2e;
$red: #e44347;
$gray: #989898;
$gray-dark: #3b3b3b;
$gray-darker: #1c1c1c;
$dark: $gray-darker;
$light: $white;

$theme-colors: (
	"green": $green,
	"green-light": $green-light,
	"blue": $blue,
	"blue-light": $blue-light,
	"purple": $purple,
	"purple-light": $purple-light,
	"orange": $orange,
	"red": $red,
	"gray": $gray,
	"gray-dark": $gray-dark,
);

// Font variables
$GT: "GTAmerican";
$GTLt: "GTAmericanLt";
$GTExp: "GTAmericanExpRg";
$GTExt: "GTAmericanExtRg";
$GTExtBd: "GTAmericanExtBd";

.gt {
	font-family: $GT;
}
.gt-light {
	font-family: $GTLt;
}
.gtexp {
	font-family: $GTExp;
}
.gtext {
	font-family: $GTExt;
}

// Use this for the transparent fade backgrounds on the applicable components
.bg-transparent {
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 50%);
	// @include media-breakpoint-up(md) {
	// 	padding-right: 3%;
	// }
}

//
