.row-hero-standard{
	display: flex;
	flex-direction: column;
	@include media-breakpoint-down(sm){
		padding-top: 0;
	}
	.hero-body{
		line-height: 1.5;
	}
	@include media-breakpoint-down( xs ){
		.video-container{
			overflow: hidden;
		}
	}
	@include media-breakpoint-down(sm){
		.video-container{
			height: 240px;
		}
	}
	@include media-breakpoint-down( md ){
		.hero-header{
			order:1
		}
		.hero-content{
			order:2;
		}
		.video-container{
			position: relative;
			margin-left:-15px;
			margin-right:-15px;
			background-size:contain;
			background-position: center center;
			background-repeat: no-repeat;
			height:325px;
			margin-bottom:20px;
			img{
				display: none;
			}
			.btn-play img{
				display: block;
			}
		}

	}
	@include media-breakpoint-up( lg ){
		.video-container{
			background:none !important;
		}
	}

	.btn-play{
		position:absolute;
		right: -8px;
		bottom: -8px;
		border-width: 7px;
		@include media-breakpoint-up( md ){
			right:auto;
			bottom:auto;
			left: 0px;
			top: -18px;
		}
		// @include media-breakpoint-down(sm){
		// 	right: -3px;
    	// 	bottom: 52px;
		// }
		@media only screen and (max-width: 400px) {
			right: -3px;
    		bottom: 52px;
		  }
	}
	.bleed-right{
		&__content{
			@include media-breakpoint-down(md){
				margin-right: 0px;
			}
			margin-right: -15px;
		}
		&__header{
			margin-left: 70px;
			@include media-breakpoint-down(md){
				margin-left: 0px;
			}

		}
		&__body{
			@include media-breakpoint-down(md){
				margin-left: 0px;
			}
			margin-left: 70px;
		}
	}
	.bleed-left{
		margin-left: -15px;
	}
}
