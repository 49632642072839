/* != MX Band
-------------------------------------------------------------- */
.mx-band-sec{
	padding-top:30px;
	padding-bottom:30px;
	@include media-breakpoint-up(md) {
		padding-top:70px;
		padding-bottom:70px;
	}
	&.theme{
		&--dark{
			background:$dark;
			color:$white;
			.form-control{
				color:$light;
				border-color:$light;
				&::placeholder {
					color: $light;
				}
			}
		}
		&--light{
			color:$dark;
			.form-control{
				color:$dark;
				&::placeholder {
					color: $dark;
				}
			}
		}
	}
}