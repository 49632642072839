.row-price-table{
	.blocks{
		display: flex;
		flex-wrap: wrap;
	}
	.block{
		background:$dark;
		color:$white;
		min-height: 333px;
		width:100%;
		margin-bottom:2px;
		@include media-breakpoint-up( md ){
			width: calc( 50% - 4px);
			margin:2px;
		}
		@include media-breakpoint-up( xl ){
			width: calc( 33% - 4px);
		}
	}
	.block__header{
		p{
			font-size: 25px;
		}
		&.blue{
			@include gradient( 'blue-blue');
		}
		&.purple{
			@include gradient( 'purple-purple');
		}
		&.orange{
			@include gradient( 'orange-red');
		}
		&.green{
			@include gradient( 'green-green');
		}
		&.red{
			@include gradient( 'red-red');
		}
		// margin-left:-15px;
		// margin-right:-15px;
		padding:20px;
		.block__header__header{
			display: flex;
			img{
				width:48px;
			}
		}
	}
	.block__body{
		padding:30px 15px;
		ul{
			list-style: disc;
		}
		a{
			color: $white;
			margin-top: 15px;
			border-bottom:4px solid rgba( $green , .5);
			position:relative;
			top: 15px;

			&:hover{
			text-decoration: none;
			border-bottom-color:rgba( $red , .4);
			}
		}
	}
}
