.hs-form-iframe {
	width: 100% !important;
}
.hs-input {
	@extend .form-control;
}
.hs-form-field {
	@extend .form-group;
	@extend .mb-3;
}

.hs-button {
	@extend .btn;
}
.hs-button.primary {
	@extend .btn-primary;
	.btn-primary{
		font-family: $GTExp;
		background:$dark;
		color:$white !important;
		display:flex;
		outline:none;
		border:none;
		position: relative;
		padding-right:120px;
		padding-left:60px;
		height:60px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: .75rem;
		text-transform: uppercase;
		&:after{
			content: '';
			@include icomoon( '\e900');
			width:60px;
			height:60px;
			@include gradient( blue-green );
			color:$white;
			display:flex;
			justify-content: center;
			align-items: center;
			font-size:.875rem;
			position: absolute;
			right:0;
			top:0;
			border-top-right-radius: .25rem;
			border-bottom-right-radius: .25rem;
			height:60px;
			padding-left: 0;
			transition: padding-left .2s ease;
		}
		&:hover{
			background:$dark;
			border:none;
			&:after{
				padding-left: 8px;
			}
		}
	}
}
.hs-form-field label {
	margin-bottom: 0.5rem;
}

.hs-error-msgs {
	@extend .list-unstyled;
}
.hs-error-msgs li {
	@extend .invalid-feedback;
	display: block !important;
}
.hs-input.invalid.error {
	@extend .is-invalid;
}
.hs-error-msgs li .hs-error-msg {
	@extend .invalid-feedback;
	display: block !important;
}
.hs_error_rollup .hs-error-msgs li {
	font-size: inherit !important;
}

.dropdown-menu-item{
	font-size: 1rem;
}
