
@include media-breakpoint-up(md){
  .mouse-follow-object {
	//   opacity: 0;
	  border-radius:100%;
	  background: #75c171;
		// width: 150px;
		// height: 150px;
		// top: 42vh;
		// left: 93vw;
		width:500px;
		height:500px;
		top:7vh;
		left:70vw;
		opacity: .7;
	  // background: radial-gradient(circle, #75c171 0%, #75c171 100%, #75c171 0%);
	  box-shadow: 0 0 66px 100px #75c171;
	  position: absolute;
	  z-index: -1;
	  transition: all 1s cubic-bezier(0.215, 0.610, 0.355, 1);
	  transform: translate(-50%, -50%);
	  opacity: 1;
	  transition: all 2.5s cubic-bezier(0.215, 0.610, 0.355, 1);
		// &.inner-object{
		// 	position: absolute;
		// 	width:501px;
		// 	height:501px;
		// 	border-radius:100%;
		// 	background: rgb(2,0,36);
		// 	top:0;
		// 	left:0;
		// }
	&.red{
		background: radial-gradient(circle, #9c4a9c 0%, #9c4a9c 100%, #9c4a9c 0%);
		box-shadow: 0 0 100px 100px #9c4a9c;
	}
	&.pos-1{
		width:500px;
		height:500px;
		top:50vh;
		left:-10vw;
		opacity: .7;
	}
	&.pos-2{
		width: 300px;
    height: 300px;
    top: 25vh;
    left: 94vw;
		opacity: .7;
		transition: all 5s cubic-bezier(0.215, 0.610, 0.355, 1);
	}
	&.pos-3{
		width: 100px;
    height: 100px;
    top: 25vh;
    left: 94vw;
		opacity: .7;
		background: $blue-light;
		box-shadow: 0 0 66px 100px $blue-light;
	}
	&.pos-4{
		width: 100px;
    height: 100px;
    top: -4vh;
    left: 0vw;
    opacity: .7;
	}
	&.pos-5{
		width:300px;
		height:300px;
		top:7vh;
		left:76vw;
		opacity: .7;
	}
	&.pos-6{
		width: 100px;
    height: 100px;
    top: 25vh;
    left: 0vw;
	}
	&.pos-7{
		width:500px;
		height:500px;
		top:50vh;
		left:-22vw;
		opacity: .7;
	}
	&.pos-8{
		width: 300px;
    height: 300px;
    top: 25vh;
    left: -18vw;
    opacity: .7;
	}
}
}
