.mx-card-row {
  a{
    transition: opacity .3s ease;
  }
  a:not(.read-more):hover{
    color: $dark;
    text-decoration: none;
  }
  .row {
    margin: auto;
  }
  &.type--images {
    img {
      margin-bottom: 15px;
    }
  }
  &.type--icons {
    img {
      background-color: white;
      border-radius: 100%;
      box-shadow: 0px 5px 30px #00000026;
      padding: 10px!important;
    }
  }
  .read-more{
    display: block;
    width: fit-content;
  }
}

.resource-card{
  a:hover{
    color: $dark;
    text-decoration: none;
  }
  .fallback{
    background-image: url('../img/logo.svg');
    background-repeat: no-repeat;
    background-size: 110px;
    background-color: $dark;
    background-position: center;
    width: 100%;
    // height: 255px;
    padding-top: 85%;
  }
  .read-more{
    display: block;
    width: fit-content;
  }
}
