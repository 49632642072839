.mx-card-row.row-card-gradient-icon {

    .cta-button-row {
        margin-left:-15px;
        margin-right:-15px;
    }

    .row.blocks {
        .block__wrapper {
            box-shadow: -15px 15px 30px #00000029;

            transition: box-shadow 0.125s ease-in-out;

            display:flex;
            flex-direction:column;

            .block__icon {
                padding:25px 20px;
                background-color: white;

                .card_icon {
                    height:85px;
                    width:auto;
                }
            }

            .mx-card-row__body {
                padding:60px 30px 45px;

                background-color:#999;
                height:auto;

                display:flex;
                flex-direction:column;
                justify-content: space-between;

                .card_body {
                    font-size:1.3rem;
                    line-height:1.8rem;
                    color:#ffffff;
                }

                &.gradient-orange-red {
                    background: transparent linear-gradient(180deg, #F16E2E 0%, #E44347 100%) 0% 0% no-repeat padding-box;
                }

                &.gradient-blue-purple {
                    background: transparent linear-gradient(180deg, #E44347 0%, #9C4A9C 100%) 0% 0% no-repeat padding-box;
                }

                &.gradient-red-purple {
                    background: transparent linear-gradient(180deg, #12ACE3 0%, #9C4A9C 100%) 0% 0% no-repeat padding-box;
                }

                &.gradient-purple {
                    background: transparent linear-gradient(180deg, #5968AE 0%, #5F2368 100%) 0% 0% no-repeat padding-box;
                }

                &.gradient-green {
                    background: transparent linear-gradient(180deg, #7DC53E 0%, #75C171 100%) 0% 0% no-repeat padding-box;
                }

                &.gradient-green-blue {
                    background: transparent linear-gradient(180deg, #7DC53E 0%, #12ACE3 100%) 0% 0% no-repeat padding-box;
                }

                .card_button_wrapper {
                    margin-top:90px;

                    .btn {
                        background-color: transparent;
                        color:#fff;

                        &::before {
                            color:#fff;
                            border-color:#fff;
                        }

                        &::after {
                            color:#fff;
                            border-color:#fff;
                        }

                        &:hover {
                            &::before {
                                border-color: #8dc53e;
                            }
                        }
                    }
                }

            }
        }

        .has_outer_link {
            .outer-link:hover {
                .block__wrapper {
                    box-shadow: -15px 15px 30px #00000026;
                }
            }
            
        }
    }
}
