
.btn-primary{
	font-family: $GTExp;
	background: transparent;
	color:$white !important;
	display:flex;
	outline:none;
	border:none;
	position: relative;
	align-items: center;
	justify-content: center;
	font-size: .75rem;
	text-transform: uppercase;
	min-width: 175px;
	padding: 20px 75px 20px 20px;
	min-height: 60px;
	overflow: hidden;
	z-index: 3;
	margin-bottom: 1em;
	&:before{
		content: '';
		position: absolute;
		@include icomoon( '\e900');
		color: $white;
		top: 0;
		right: 0;
		width: 60px;
		height: 100%;
		background: linear-gradient(51deg, rgba(141,197,62,1) 16%, rgba(18,172,227,1) 100%);
		z-index: -1;
		display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
		transition: width .3s ease;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: calc(100% - 60px);
		background-color: $black;
		transform: translateX(0);
		z-index: -2;
	}
	&:hover{
		&:before{
			width: 100%;
		}
	}
}


.btn-secondary, .btn-dark{
	font-family: $GTExp;
	position: relative;
	color: $black;
	background: $white;
	border: 0px solid transparent;
	border-radius: 5px;
	padding-right: 55px;
	// padding-left: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: .75rem;
	text-transform: uppercase;
	transition: border-color .2s ease;
	min-width: 175px;
	z-index: 3;
	overflow: hidden;
	transition: border-left-color .1s ease;
	transition-delay: .25s;
	&:before{
		content: '';
		position: absolute;
		@include icomoon( '\e900');
		color: $black;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		border: 1px solid $gray;
		border-radius: 5px;
		z-index: -2;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 20px;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		transform: translateX(0);
		z-index: -1;
		border: 1px solid $gray;
		border-radius: 5px;
		border-right-width: 0;
		transition: width .3s ease;
	}
	&:hover{
		color: $black;
		background-color: transparent;
		border-color: transparent;
		border-left-color: $black;
		&:after{
			width: 0;
			animation-name: shift;
			animation-duration: .01s;
			animation-delay: .25s;
			animation-fill-mode: forwards;
		}
		&:before{
			border-color: $black;
		}
	}
}
.btn-default {
	background: $white;
	color:$dark;
	border-color: $dark;
	border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75rem;
	transition: all .3s ease;
	transition-delay: .25s;
	&:hover {
		background: $gray-100;
		border-color: $black;
	}
}

@keyframes shift{
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-2px);
	}
}


.btn-dark{
	background: $dark;
	&:before{
		color: $white;
	}
	&:hover{
		&:before{
			border-color: $green;
		}
		color: $white;
	}
}

.btn-play{
	background:black;
	display: block;
	outline: none;
	cursor: pointer;
	border-radius: 100%;
	width:90px;
	height:90px;
	@include flex-center();
	border:9px solid white;
	transition: border .2s ease-out;
	width:60px;
	height:60px;
	border-width: 4px;
	@include media-breakpoint-up(md){
		width: 110px;
		height: 110px;
	}
	img{
		width: 18px;
	}
	&:hover{
		border-color: black;
		transform:scale(1);
		transform-origin: center;
		img{
			transform: scale(1.2);
		}
	}
	img{
		width: 24px;
		height: auto;
		margin-left:3px;
		transition: transform .3s ease;
	}
	&:focus{
		outline: none;
		box-shadow: none;
	}
}
