.row-media {
	margin: 0px auto;
	position: relative;
	.btn-play {
		position: absolute;
		left: 20px;
		bottom: -30px;
		@include media-breakpoint-up(md) {
			position: absolute;
			left: auto;
			bottom: auto;
			top: -16px;
			right: -16px;
		}
	}
	.video-container {
		position: relative;
	}
	.caption__body {
		font-size: 1.325;
		margin-top: 30px;
		a {
			color: #1c1c1c;
			border-bottom: 4px solid rgba(141, 197, 62, 0.5);
			&:hover {
				color: #1c1c1c;
				border-bottom-color: rgba(228, 67, 71, 0.4);
				text-decoration: none;
			}
		}
	}
	.video-caption {
		padding: 15px;
	}

	&.layout--caption_right,
	&.layout--caption_left {
		max-width: 1800px;
		margin: 0px auto;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
		.video-container {
			@include media-breakpoint-up(md) {
				width: 70%;
			}
		}
		.video-caption {
			@include media-breakpoint-up(md) {
				width: 30%;
				padding: 0px 30px;
			}
			@include media-breakpoint-up(lg) {
				padding: 0px 50px;
			}
			@include media-breakpoint-up(xl) {
				padding: 0px 100px;
			}
		}
	}
	&.has-video {
		.video-caption {
			padding: 15px 15px 0px 100px;
		}
	}
	&.layout--caption_left {
		@include media-breakpoint-up(md) {
			.video-container {
				order: 2;
			}
			.btn-play {
				right: auto;
				left: -16px;
			}
		}
	}
	&.layout--caption_left {
		&.bg-transparent {
			@include media-breakpoint-up(md) {
				padding-left: 0;
				// padding-right: 3%;
			}
		}
	}
	&.layout--caption_overlay {
		max-width: 1200px;
		margin-bottom: 120px;
		.video-caption {
			@include media-breakpoint-up(md) {
				width: 388px;
				position: absolute;
				right: 0;
				top: 80%;
				background: rgba(255, 255, 255, 0.8);
				padding: 25px 50px;
			}
		}
		&.has-video {
			@include media-breakpoint-up(md) {
				margin-bottom: 200px;
			}
			.video-caption {
				padding: 15px 15px 0px 100px;
			}
		}
		@include media-breakpoint-up(md) {
			.btn-play {
				left: 50%;
				top: 50%;
				right: auto;
				bottom: auto;
				transform: translate(-50%, -50%);
			}
		}
	}
}
