
.about-menu-sidebar{
	background-color: $dark;
	top: 58px;
	//bottom: 0;
	right: -100%;
	height: 100%;
	width:100%;
	padding-right: 1rem;
	padding-left: 1rem;
	overflow-y: visible;
	transition-timing-function: ease-in-out;
	transition-duration: .3s;
	transition-property: all;
	z-index: 99999999;
	position: fixed;
	&.open{
		right: 0%;
		visibility: visible;
		transition: all .3s cubic-bezier(1,-0.05,.34,.98);
	}
	@include media-breakpoint-up( md ){
		width:356px;
	}
	.btn-close{
		position: absolute;
		top:-58px;
		width:151px;
		height:58px;
		background:$dark;
		display: flex;
		justify-content: flex-start;
		padding-left: 25px;
		align-items: center;
		left:0;
		cursor: pointer;
		outline: none;
		border:none;
		&:focus{ outline:none; }
		[class^='icon-']::before{
			color:$green;
		}
	}
	.menu{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		flex-direction: column;
	}
	.menu-item{
		text-align: center;
		padding: 24px 0px;
		border-bottom: 1px solid $gray;
		width: 75%;
		&:last-child(){
			border-bottom:none;
		}
		.icon-chevron{
			transition: all .2s ease-in;
			display: inline-block;
			transform:rotate(-90deg);
			margin-left:5px;
			font-size:.5rem;
		}
		a{
			transition: all .2s ease-in;
			font-family: $GTExp;
			cursor: pointer;
		}
		&:hover{
			a{color:$green;}
			transition: all .2s ease-out;
			.icon-chevron{
				font-size:.5rem;
				transform:rotate(-90deg) translateY(5px);
				&::before{ color:$green;}
			}

		}
	}
	.menu--child{
		justify-content: initial;
		background-color: $dark;
		top: 58px;
		right: -100%;
		height: 100%;
		width:100%;
		padding-right: 1rem;
		padding-left: 1rem;
		overflow-y: visible;
		transition-timing-function: ease-in-out;
		transition-duration: .3s;
		transition-property: all;
		z-index: 99999999;
		position: fixed;
		@include media-breakpoint-up( md ){
			width:356px;
		}
		&.open{
			right: 0%;
			visibility: visible;
			transition: all .3s cubic-bezier(1,-0.05,.34,.98);
		}
		.menu__header{
			width: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 30%;
			.menu__header__title{
				color:$white;
				margin-top: 20%;
			}
		}
		.menu__items{
			width: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		.btn-back{
			.icon-arrow{
				display: inline-block;
				transform: rotate(180deg);
				margin-right:10px;
				transition: all .2s linear;
				font-size:.75rem;
			}
			&:hover{
				color:$green;
				.icon-arrow:before{
					color:$green;
				}
				.icon-arrow{
					transition: all .2s linear;
					transform: rotate(180deg) translateX(5px);
				}
			}
		}
	}

	a{
		color:$white;
		font-family: $GTExp;
	}
	.menu_toolbar{
		display: none;
	}
	@include media-breakpoint-down( md ){
		.menu__header{
			display: none;
		}
		.menu_toolbar{
			display: flex;
		}
		.btn-close{
			display: none;
		}
	}

}
