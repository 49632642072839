body.mega-menu--open{
}

.mega-menu{
	pointer-events: none;
	transform:translateY(-200vh);
	background:$dark;
	position:fixed;
	z-index: 4000;
	height:100vh;
	width:100%;
	color:$white;
	top:58px;
	opacity: 0;
	ul, li{ list-style: none;}
	a{ color:$white; }
	&.active{
		transform:translateY(0);
		pointer-events: all;
		opacity: 1;
	}
	.mega-menu__header{
		font-size:1.75rem;
		display: none;
	}
	.mega-menu__toolbar{
		margin-top:40px;
		padding:12px 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&.has-overview{
			border-bottom:1px solid $gray;
		}
	}
	.mega-menu__columns	{
		margin-top:40px;
	}
	.menu-col{
		margin-bottom: 60px;
		.col-header{
			display: flex;
			align-items: center;
			margin-bottom:32px;
			.col-header__text{
				font-size: 1rem;
			}
			.icon_bg{
				background-color: $dark;
		    border-radius: 100%;
		    padding: 15px;
		    box-shadow: 0px 5px 30px rgba(0, 0, 0, .35);
				margin-right: 10px;
			}
			img{
				width:28px;
			}
			&.linked{
				.col-header__text{
					font-size: 1rem;
					font-weight: bold;
					&:after{
						font-weight: normal;
						font-size:.75rem;
						margin-left:20px;
						@include icomoon( '\e900');
					}
				}
				&:hover{
					.col-header__text{
						color:$green;
						&:after{
							color:$green;
							text-decoration: none;
							transform:translateX(5px);
						}
					}
				}
			}
			&:hover{
				text-decoration: none !important;
			}
		}
		.col-featured-content__header{
				font-family: $GT;
				font-size:.75rem;
				color:$gray;
		}
		.col-featured-content__text{
			font-family: $GTLt;
		}
		.links{
			list-style: none;
			margin:0px;
			padding:0px;
			.link{
				margin-bottom:32px;
				font-family: $GTExt;
				a{
					position: relative;
					transition: color .3s ease;
					&:after{
						content: "";
						  position: absolute;
						  width: 100%;
						  height: 1px;
						  bottom: 0;
						  left: 0;
						  background-color: $green;
						  visibility: hidden;
						  transform: scaleX(0);
						  transition: transform 0.3s ease-in-out 0s;
					}
					&:hover{
						color: $green;
						text-decoration: none;
						&:after{
							visibility: visible;
	  					transform: scaleX(1);
						}
					}
				}
			}
		}
	}
	.btn-overview,.btn-back{
		font-size:.75rem;
		cursor: pointer;
		.icon-arrow{
			display: inline-block;
		}
		&:hover{
			color:$green;
			text-decoration: none;
			.icon-arrow:before{
				color:$green;
			}
			.icon-arrow{
				transition: all .2s linear;
				transform: translateX(5px);
			}
		}
	}
	.btn-overview{
		.text{
			display: inline;
			font-family: $GTExp;
		}
		.icon-arrow{
			margin-left:20px;
		}
	}

	.btn-back{
		display: none;
		.icon-arrow{
			transform: rotate(180deg);
			margin-right:20px;
		}
		&:hover{
			.icon-arrow{
				transform: rotate(180deg) translateX(5px);
			}
		}
	}
	@include media-breakpoint-down( md ){
		body.navbar-open & {
			padding:50px 30px;
			.mega-menu__toolbar{
				border-bottom:1px solid $gray;
			}
			.mega-menu__header{
				display: inline-block;
			}
			.btn-back{
				display: inline-block;
			}
			.btn-overview .text{
				display: none;
			}
			.mega-menu__columns	{
				max-height: 65vh;
				overflow: auto;
				padding-bottom: 20vh;
			}

			.menu-col{
				margin-bottom:24px;
				.col-header{
					margin-bottom:0px;
					img{
						margin-left:-24px;
					}
				}
				.links .link{
					margin-bottom:24px;
				}
			}
		}
	}


	::-webkit-scrollbar-thumb {
		background-color:rgba( $green , .5 ) ;
	}



}
