/* != Footer
-------------------------------------------------------------- */
.main-footer{
	position: relative;
	background:$gray-darker;
	color:$light;
	padding-bottom:75px;
	z-index: 12;
	a{
		color:$light;
		font-size:.875rem;
	}
	.main-footer__search{

        display:none; /* Remove footer search - 12/23/2022 */

		padding-top:100px;
		padding-bottom:100px;
		margin-bottom:75px;
		border-bottom:1px solid $gray-dark;

		.input-group{
			display: flex;
			align-items: center;
			input{
				height:48px;
				background:$black;
				color:$white;
				border-top-right-radius: 40px;
				border-bottom-right-radius: 40px;
				outline: none;
				border:none;
				font-size:1.5rem;
				&:focus{
					box-shadow: none;
				}
				&::placeholder{
					color:$white;
				}
			}
			.input-group-prepend{
				@include gradient(blue-green);
				height:48px;
				width:64px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-top-left-radius: 40px;
				border-bottom-left-radius: 40px;
				.icon-search{
					color:$black;
					font-size:1.75rem;
				}
			}
		}
		@include media-breakpoint-down( sm ){
			.input-group{
				padding-top:20px;
			}
		}

	}
	.link{
		margin-bottom:20px;
		a{
			&:hover{
				color: $white !important;
				text-decoration: underline !important;
			}
		}
	}
	.main-footer__links{

        padding-top:100px; //Mike D - 12/23/2022 Remove Footer Search, match the original padding

		p{
			display: inline-block;
			color:$gray;
			padding-bottom: 20px;
			margin-top:15px;

			@include media-breakpoint-down( sm ){
				padding-top:20px;
			}
		}

	}
}
.utility-footer{
	position: relative;
	background:$black;
	color:$gray;
	z-index: 12;
	a{
		color:$gray;
		font-size:.875rem;
	}
	ul {
		display: inline;
		list-style: none;
		li, a{
			display: inline;
		}
	}
}

.footer-social{
	width: 50%;
	position:relative;
	top: 25px;
	display: flex;
	justify-content: center;
	z-index: 13;
	@include media-breakpoint-up(sm){
		margin-left: 50px;
	}
	@include media-breakpoint-down(xs){
		width: 100%;
	}
	ul{
		li{
			margin-right: 15px;
			&:last-child{ //phone lg icon only display at larger sizes
				margin-left: 10px;
				@include media-breakpoint-down(md){
					display: none;
				}
			}
			&:nth-child(5){ //phone sm icon don't display larger sizes
				@include media-breakpoint-up(lg){
					display: none;
				}
			}
			.social{
				background: $dark;
				border-radius: 50%;
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				// margin: 0px 20px;
				color: $dark;
				box-shadow: 0px 5px 10px 0px black;

				&:first-child{
					color: $green;
					transform: scale(1.1);
				}
				&__call{
					width:fit-content;
					padding: 0px 20px;
					border-radius: 500px;
					font-family: $GT;
					color: $white !important;
					.icon-phone{
						color: $green;
						// margin-right: 10px;
						@include media-breakpoint-up(lg){
							margin-right: 10px;
						}
					}
				}

			}
		}
	}
}


@include media-breakpoint-down( xs ){
	.utility-footer{
		padding-bottom:20px;
		.nav-link{
			padding: 0px;
		}
	}
	.copy{
		display: block;
		padding:10px 0;
	}
	.footer-menu-item{
		&:after{
			content: '';
			display: block;
			height:1px;
			background:$gray-dark;
		}
		padding-top:10px;
		padding-bottom:10px;
		margin-top:10px;
		margin-bottom:10px;
		&:first-of-type{
			&:before{
				content: '';
				display: block;
				height:1px;
				background:$gray-dark;
				margin-bottom: 20px;
			}
		}
		&:last-of-type{
			&:after{
				content : none;
			}
		}
		.level-2{
			display: flex;
			flex-wrap: wrap;
			.link{
				width: 50%;
				padding-right:10px;
			}
		}
	}
}
