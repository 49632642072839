/* != Custom
-------------------------------------------------------------- */
body{
	overflow-x: hidden;
}

// @include media-breakpoint-up( md ){
	section{
		padding-top: 60px;
		padding-bottom: 60px;
		position: relative;
		@include media-breakpoint-up(md){
			padding-bottom: 120px;
		}
		&.animate{
			.display-1, .display-2, .eyebrow, .content, img, .content-block {
				opacity: 0;
				transform: translateY(30px);
			}
		}
	}
// }

img{
	width: 100%;
}

ul {
	list-style-type: '\2014'+ ' ';
}

p a {
	border-bottom: 4px solid rgba(141,197,62,.5);

	&:hover {
		text-decoration: none;
		border-bottom-color: rgba(228,67,71,.4);
		color: #1c1c1c;
	}
}

// .swiper-wrapper{
// 	overflow:hidden;
// }
	.swiper-button-next,
	.swiper-button-prev{
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		background-image:none;
		font-size:50px;
		line-height:50px;
		height: 66px;
    width: 66px;
    border: 1px solid $gray;
    border-radius: 100%;
		color:$gray-200;
		opacity:1;
		&:before{
			@include icomoon( '\e900');
			font-size:.875rem;
			color: $black;
			padding: 25px 22px;
			top: 0;
			position: absolute;
		}
	}
	.swiper-button-prev{
		&:before{
			transform: rotate(180deg);
		}
	}
	.swiper-slide{
		>.container{
			position:relative;
			top:50%;
			transform:translateY(-50%);
		}
	}



/**
** disable bootstrap collapsing animation
**/
.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}



// ::-webkit-scrollbar {
//     width: 12px;
// }
//
// ::-webkit-scrollbar-track {
// 	background-color:$dark;
//     border-radius: 0px;
// }
//
// ::-webkit-scrollbar-thumb {
//     border-radius: 0px;
// 	background-color: $green  ;
//     box-shadow: inset 0 0 6px rgba( $dark, .4);
// }
