body.mega-menu--open{
}

.mega-menu{
	pointer-events: none;
	transform:translateY(-120vh);
	background:$dark;
	position:fixed;
	z-index: 4000;
	height:100vh;
	width:100%;
	color:$white;
	top:60px;

	ul, li{ list-style: none;}
	a{ color:$white; }
	&.active{
		transform:translateY(0);
		pointer-events: all;
	}
	.menu-col{
		.col-header{
			display: flex;
			align-items: center;
			margin-bottom:32px;
			.col-header__text{
				font-size: 1rem;
			}
			img{
				width:60px;
			}
		}
		.links{
			list-style: none;
			margin:0px;
			padding:0px;
			.link{
				margin-bottom:32px;
			}
		}
	}
}