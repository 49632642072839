.mx-card-row.row-card-with-icon {

    .cta-button-row {
        margin-left:-15px;
        margin-right:-15px;
    }

    .row.blocks {
        .block__wrapper {
            box-shadow: -15px 15px 30px #0000001A;
            transition: box-shadow 0.125s ease-in-out;
            background-color: #ffffff;

            .mx-card-row__body {
                padding:25px;
                padding-bottom:50px;

                .card_body {
                    font-size:1.3rem;
                    line-height:1.8rem;
                }

            }

            .card_icon {
                height:85px;
                width:auto;
                margin-bottom:25px;
            }
        }

        .has_outer_link {
            .outer-link:hover {
                .block__wrapper {
                    box-shadow: -15px 15px 30px #00000026;
                }
            }
            
        }
    }
}
