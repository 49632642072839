.row-hero{
	.hero-body{
		padding: 30px 0;
	}
	.full-bleed{
		@include media-breakpoint-up(lg){
			padding-left: 70px;
		}
	}
	.hero-image{
		@include media-breakpoint-up(xl){
			margin-top: -140px;
		}
		&__full-bleed{
			margin-right: -15px !important;
			position:relative;
			left: 15px;
			@include media-breakpoint-down(md){
				left: 0px;
			}
			@include media-breakpoint-up(xl){
				margin-top: -50px;
			}

		}
	}

}
